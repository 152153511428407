import React, { useState } from 'react';

const QuestionForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        contactNumber: '',
        email: '',
        question: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data:', formData);
        // Handle form submission logic here (e.g., send data to server)
    };

    return (
        <div>
            {/* Desktop View */}
            <div className="hidden xl:block bg-mainColor border-4 border-mainColor rounded-3xl p-4 mb-10">
                <div className="w-full mx-auto grid grid-cols-2 gap-8 px-10">
                    <div className='text-right justify-center items-center my-auto'>
                        <h2 className="mt-4 text-5xl text-white">ASK A QUESTION</h2>
                        <h2 className="mb-4 pb-5 text-6xl leading-none text-secondaryColor">WE'RE HERE TO HELP</h2>
                        <p className="mb-4 text-2xl text-white">
                            If you have any questions, feel free to ask.
                        </p>
                    </div>
                    <div className="bg-mainColor px-8 py-4 rounded-2xl">
                        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                            <div className="mb-4">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="firstName">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="lastName">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="contactNumber">
                                    Contact Number
                                </label>
                                <input
                                    type="tel"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4 col-span-2">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="question">
                                    Your Question
                                </label>
                                <textarea
                                    id="question"
                                    name="question"
                                    value={formData.question}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    rows="4"
                                    required
                                />
                            </div>
                            <div className="col-span-2 flex items-center justify-center mt-2">
                                <button
                                    type="submit"
                                    className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Mobile View */}
            <div className="xl:hidden block bg-mainColor border-4 border-mainColor rounded-3xl p-4 mb-10">
                <div className="w-full mx-auto flex flex-col items-center">
                    <div className='text-center my-auto'>
                        <h2 className="mt-4 text-2xl text-white">ASK A QUESTION</h2>
                        <h2 className="mb-2 text-4xl leading-none text-secondaryColor">WE'RE HERE TO HELP</h2>
                        <p className="mb-4 text-2xl text-white">
                            If you have any questions, feel free to ask.
                        </p>
                    </div>
                    <div className="bg-mainColor px-8 py-4 rounded-2xl w-full text-center">
                        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
                            <div className="mb-2">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="firstName">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="lastName">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="contactNumber">
                                    Contact Number
                                </label>
                                <input
                                    type="tel"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="question">
                                    Your Question
                                </label>
                                <textarea
                                    id="question"
                                    name="question"
                                    value={formData.question}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    rows="4"
                                    required
                                />
                            </div>
                            <div className="flex items-center justify-center mt-2">
                                <button
                                    type="submit"
                                    className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionForm;
