import React, { useState } from 'react';

const CareerForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        contactNumber: '',
        email: '',
        file: null,
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data:', formData);
        // Handle form submission logic here (e.g., send data to server)
    };

    return (
        <div className='bg-white px-5 mx-8 py-3 shadow-lg' style={{ borderRadius: "24px" }}>
            <div className=''>
                <h2 className="mx-3 mt-4 text-3xl text-shadow-lg">WORK WITH US</h2>
                <h2 className="mx-3 pb-5 text-4xl leading-none text-shadow-lg text-mainColor">JOIN OUR TEAM</h2>
                <p className="mx-3 mb-4 text-xl">
                    If you are interested to be part of our team, you can send us your details and we'll get back to you.
                </p>
            </div>
            <div className="max-w-xl bg-mainColor mx-auto" style={{ borderRadius: "24px" }}>
                <form onSubmit={handleSubmit} className="rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <label className="block text-white text-xl font-bold mb-2" htmlFor="firstName">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-xl font-bold mb-2" htmlFor="lastName">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-xl font-bold mb-2" htmlFor="contactNumber">
                            Contact Number
                        </label>
                        <input
                            type="tel"
                            id="contactNumber"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-xl font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-xl font-bold mb-2" htmlFor="file">
                            File Upload (PDF/Word)
                        </label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            accept=".pdf,.doc,.docx"
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />

                    </div>
                    <div className="flex items-center justify-center mt-6">
                        <button
                            type="submit"
                            className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CareerForm;
