import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import AboutUs from './pages/about_us';
import HospiceServices from './pages/hospice_services';
import HealthCareTeam from './pages/healthcare_team';
import ContactUs from './pages/contact_us';
import Navi from './components/navigation_bar';
//import Header from './components/header_bar';
import Footer from './components/footer_bar';

const App = () => {
  useEffect(() => {
    const errorHandler = (e) => {
      if (e.message.includes("ResizeObserver loop completed with undelivered notifications")) {
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };

    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);
  return (
    <Router>
      <div>
        <Navi />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<HospiceServices />} />
          <Route path="/team" element={<HealthCareTeam />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
