import React from 'react';
import HeaderHero from '../components/headerhero_section';
import HeaderImage from '../assets/aboutus.jpg';
import Breadcrumbs from '../components/breadcrumbs';
import ChapImage from '../assets/chap.png';
import DOHSImage from '../assets/dohs.png';
import PromiseImage from '../assets/promise.jpg';
import MissionImage from '../assets/mission.jpg';
import WhatIsHospiceImage from '../assets/whatishospice.jpg';

const AboutUs = () => {
    return (
        <div className='font-twcen border-b-1 border-black shadow-md'>
            <HeaderHero
                imageUrl={HeaderImage}
                title="About Us"
            />
            <div className='flex flex-col xl:w-3/4 w-full items-center justify-center mx-auto px-5 xl:px-0'>
                <div className='w-full items-center text-center xl:text-left mt-5'>
                    <Breadcrumbs />
                </div>
                <div className='w-full border-b-2 border-dashed border-gray-500 mt-4'></div>

                <div className='w-full my-5 xl:text-2xl text-xl'>
                    {/* Desktop Text */}
                    <div className='hidden xl:block'>
                        <div
                            className="flex flex-row justify-around items-center rounded-3xl my-3 py-5"
                        >
                            <div className='ml-10 pr-10'>
                                <p className='mb-4'>
                                    Love and Faith Hospice Services Inc. is a privately owned hospice agency founded in 2020 by 2 hospice nurse professionals.
                                </p>
                                <p className='mb-4'>
                                    We are a licensed Hospice Agency by the California Department of Public Health.</p>
                                <p className='mb-4'>
                                    Medicare-MediCAL certified through CHAP (Community Health Accreditation Partner).</p>
                            </div>
                            <div className='mr-10'>
                                <img src={ChapImage} alt="Promise to you image" className="w-64 rounded-3xl" />
                            </div>
                            <div className='mr-10'>
                                <img src={DOHSImage} alt="Promise to you image" className="w-60 rounded-3xl" />
                            </div>
                        </div>
                    </div>

                    {/* Mobile Text */}
                    <div className='xl:hidden block text-justify text-xl'>
                        <p className='mb-4'>
                            Love and Faith Hospice Services Inc. is a privately owned hospice agency founded in 2020 by 2 hospice nurse professionals.
                        </p>
                        <p className='mb-4'>
                            We are a licensed Hospice Agency by the California Department of Public Health.</p>
                        <p className='mb-4'>
                            Medicare-MediCAL certified through CHAP (Community Health Accreditation Partner).</p>
                    </div>
                </div>

                {/* Desktop Our Promise To You*/}
                <div id="promise-desktop" className="hidden xl:block bg-mainColor text-white" style={{ borderRadius: "40px" }}>
                    <div
                        className="flex flex-row justify-around items-center rounded-3xl my-3 py-5"
                    >
                        <div className='ml-10 pr-10'>
                            <h2 className="text-5xl pb-5 font-semibold text-secondaryColor">OUR PROMISE TO YOU</h2>
                            <p className="text-2xl text-justify">Love & Faith Hospice Services Inc. is dedicated towards designing a comprehensive plan of care that meets, respects, and prioritizes the needs of patients and their families in all aspects- physical, psychological, emotional, social and spiritual.<br /><br />Our interdisciplinary team believes in patient-directed medical care that upholds that dignity is paramount in providing excellent service.</p>
                        </div>
                        <div className='mr-10'>
                            <img src={PromiseImage} alt="Promise to you image" className="max-h-720px w-auto rounded-3xl" />
                        </div>
                    </div>
                </div>

                {/* Mobile Our Promise To You*/}
                <div id="promise-mobile" className="xl:hidden block bg-mainColor text-white" style={{ borderRadius: "40px" }}>
                    <div
                        className="flex flex-col justify-center items-center rounded-3xl my-2 py-5"
                    >
                        <div className='mx-5 mb-5'>
                            <h2 className="text-3xl pb-3 font-semibold text-center text-secondaryColor">OUR PROMISE TO YOU</h2>
                            <p className="text-xl text-center">Love & Faith Hospice Services Inc. is dedicated towards designing a comprehensive plan of care that meets, respects, and prioritizes the needs of patients and their families in all aspects- physical, psychological, emotional, social and spiritual.<br /><br />Our interdisciplinary team believes in patient-directed medical care that upholds that dignity is paramount in providing excellent service.</p>
                        </div>
                        <div className='mx-5'>
                            <img src={PromiseImage} alt="Promise to you image" className="max-h-720px w-auto rounded-3xl" />
                        </div>
                    </div>
                </div>

                {/* Desktop Mission Statement*/}
                <div id="mission-desktop" className="hidden xl:block text-black">
                    <div
                        className="flex flex-row justify-around items-center rounded-3xl my-3 py-5"
                    >
                        <div className='mr-5'>
                            <img src={MissionImage} alt="Promise to you image" className="max-h-720px w-auto rounded-3xl" />
                        </div>
                        <div className='ml-5'>
                            <h2 className="text-5xl pb-5 font-semibold text-mainColor text-right">OUR MISSION</h2>
                            <p className="text-2xl text-right">We are committed to providing superior services to our patients and their families to ensure a dignified, comfortable and peaceful end-of-life experience.</p>
                        </div>
                    </div>
                </div>

                {/* Mobile Mission Statement*/}
                <div id="mission-mobile" className="xl:hidden block text-black">
                    <div
                        className="flex flex-col justify-around items-center rounded-3xl mt-10"
                    >
                        <div className='mb-5'>
                            <h2 className="text-5xl mb-5 font-semibold text-mainColor text-center">OUR MISSION</h2>
                            <p className="text-xl text-center">We are committed to providing superior services to our patients and their families to ensure a dignified, comfortable and peaceful end-of-life experience.</p>
                        </div>
                        <div className='mx-5 my-5'>
                            <img src={MissionImage} alt="Promise to you image" className="max-h-720px w-auto rounded-3xl" />
                        </div>
                    </div>
                </div>

                {/* Desktop What is Hospice*/}
                <div id="hospice-desktop" className="hidden xl:block text-black pb-5" style={{ borderRadius: "40px" }}>
                    <div
                        className="flex flex-row justify-around items-center rounded-3xl my-3 py-5"
                    >
                        <div className='max-w-2xl'>
                            <h2 className="text-5xl pb-5 font-semibold text-mainColor">WHAT IS HOSPICE?</h2>
                            <p className="text-2xl text-justify">It’s a philosophy of caring for terminally ill patients, providing guidance for palliative care and support to their families and loved ones. Choosing hospice means choosing to optimize quality of life, but it also takes an honest realization that we are no longer trying to cure the disease, but working to minimize the symptoms of the disease and keep you or your loved one comfortable</p>
                        </div>
                        <div className='bg-mainColor pb-3 ml-10' style={{ borderRadius: "24px" }}>
                            <img src={WhatIsHospiceImage} alt="What is Hospice image" className="max-h-720px w-auto rounded-3xl" />
                        </div>
                    </div>
                </div>

                {/* Mobile What is Hospice*/}
                <div id="hospice-mobile" className="xl:hidden block text-black">
                    <div
                        className="flex flex-col justify-around items-center rounded-3xl mt-5 mb-10"
                    >
                        <div className='mb-5'>
                            <h2 className="text-5xl mb-5 font-semibold text-mainColor text-center">WHAT IS HOSPICE?</h2>
                            <p className="text-xl text-center">It’s a philosophy of caring for terminally ill patients, providing guidance for palliative care and support to their families and loved ones. Choosing hospice means choosing to optimize quality of life, but it also takes an honest realization that we are no longer trying to cure the disease, but working to minimize the symptoms of the disease and keep you or your loved one comfortable</p>
                        </div>
                        <div className='bg-mainColor pb-3 mx-5 my-5' style={{ borderRadius: "24px" }}>
                            <img src={WhatIsHospiceImage} alt="What is Hospice image" className="max-h-720px w-auto rounded-3xl" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
