import React from 'react';
import logo from '../assets/LogoTransparentWeb.png';
import { IoLocationSharp, IoCall, IoMail } from "react-icons/io5";

const Footer = () => {
    return (
        <div className='flex flex-col lg:flex-row items-center justify-center w-full font-twcen p-5'>
            <div className='block items-center justify-center text-center'>
                <img src={logo} alt="Logo" className="p-4 max-h-48" />
                <h1 className='lg:block hidden text-xl lg:text-3xl mt-5 lg:mt-10'>
                    © Copyright 2024
                </h1>
            </div>
            <div className='flex flex-col m-2 lg:ml-20 items-start justify-center p-2'>
                <div className='m-5 text-center lg:text-left'>
                    <h1 className='lg:text-3xl text-2xl'>
                        GET IN TOUCH
                    </h1>
                    <h1 className='lg:text-6xl text-5xl font-semibold text-mainColor'>
                        CONTACT DETAILS
                    </h1>
                </div>
                <div className='flex flex-row items-center justify-start text-left lg:mt-5 mt-2'>
                    <IoLocationSharp className="text-mainColor lg:text-5xl text-3xl lg:mr-4 mr-5" />
                    <div>
                        <h1 className='lg:text-3xl text-xl text-mainColor'>Address:</h1>
                        <h1 className='lg:text-3xl text-xl'>7365 Carnelian St. Suite 115</h1>
                        <h1 className='lg:text-3xl text-xl'>Rancho Cucamonga, CA 91730</h1>
                    </div>
                </div>
                <div className='flex flex-row items-center justify-start text-left lg:mt-7 mt-2'>
                    <IoCall className="text-mainColor lg:text-5xl text-3xl lg:mr-4 mr-5" />
                    <div>
                        <h1 className='lg:text-3xl text-xl text-mainColor'>Phone:</h1>
                        <h1 className='lg:text-3xl text-xl'>(909) 284-8901</h1>
                    </div>
                </div>
                <div className='flex flex-row items-center justify-start text-left lg:mt-7 mt-2'>
                    <IoMail className="text-mainColor lg:text-5xl text-3xl lg:mr-4 mr-5" />
                    <div>
                        <h1 className='lg:text-3xl text-xl text-mainColor'>Email:</h1>
                        <a href="mailto:info@loveandfaithhospice.com" className="lg:text-3xl text-xl no-underline hover:underline">
                            info@loveandfaithhospice.com
                        </a>
                    </div>
                </div>
            </div>
            <div className='flex flex-col 2xl:flex-row items-center 2xl:justify-center lg:mt-10 mt-5 lg:pt-10 lg:ml-10'>
                <iframe
                    className='w-96 h-64'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.8900411454526!2d-117.57257628428865!3d34.11325982438485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c34b6e96da9ec3%3A0xf60954a16f1e7389!2s7365%20Carnelian%20St%20Suite%20115%2C%20Rancho%20Cucamonga%2C%20CA%2091730%2C%20USA!5e0!3m2!1sen!2sph!4v1632823210271!5m2!1sen!2sph"
                    allowFullScreen
                    loading="lazy"
                    title="Google Map"
                ></iframe>
                <h1 className='lg:hidden block text-xl lg:text-3xl pt-5'>
                    © Copyright 2024
                </h1>
            </div>
        </div>
    );
}

export default Footer;
