import React from 'react';

const InsuranceModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 font-twcen overflow-y-auto">
            <div className="bg-mainColor rounded-3xl shadow py-8 px-4 w-3/4 xl:w-1/4 max-h-full overflow-y-auto">
                <div className="text-center">
                    <h2 className="text-5xl text-white mb-4">Accepted Insurances</h2>
                    <ul className="text-2xl text-white">
                        <li className="mb-2">Medicare (PART A)</li>
                        <li className="mb-2">Medi-CAL 100%</li>
                        <li className="mb-2">Managed Medi-CAL</li>
                    </ul>
                    <div className="mt-4">
                        <button
                            onClick={onClose}
                            className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsuranceModal;
