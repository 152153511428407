import React, { useState } from 'react';
import Menu from '../components/menu_mobile';
import logo from '../assets/LogoTransparentWeb.png';
import { IoMenu, IoClose } from "react-icons/io5";

function Navi() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    // Function to toggle mobile menu
    const toggleMobileMenu = () => {
        setShowMobileMenu(prevState => !prevState);
    };

    return (
        <nav className="xl:bg-mainColor shadow-lg mx-auto xl:h-28 h-40 px-4 font-twcen text-2xl xl:rounded-bl-4rem xl:rounded-br-4rem items-center z-50 relative flex justify-center" style={{ maxWidth: '1480px' }}>
            <div className="flex" style={{ maxWidth: '1480px' }}>
                <div className='xl:hidden block'>
                    <img src={logo} alt="Logo" className="logo max-h-32" />
                </div>
                <button onClick={toggleMobileMenu} className="xl:hidden block md:ml-80 ml-16">
                    <IoMenu className="text-3xl" />
                </button>
                <div className="hidden xl:block">
                    <Menu closeMenu={toggleMobileMenu} />
                </div>
            </div>

            {showMobileMenu && (
                <>
                    <div className="xl:hidden fixed inset-0 bg-black bg-opacity-70 z-70" onClick={toggleMobileMenu}></div>
                    <div className="xl:hidden fixed inset-y-0 right-0 transform translate-x-0 transition duration-300 ease-in-out z-60">
                        <div className="bg-mainColor w-64 h-full">
                            <div className='flex flex-row items-center justify-center mx-auto text-center bg-white'>
                                <div className='xl:hidden block p-2'>
                                    <img src={logo} alt="Logo" className="logo max-h-32" />
                                </div>
                                <div className='text-center p-5'>
                                    <button onClick={toggleMobileMenu} className="items-center justify-center mx-auto">
                                        <IoClose className="text-3xl text-mainColor mt-2" />
                                    </button>
                                </div>
                            </div>
                            <Menu closeMenu={toggleMobileMenu} />
                        </div>
                    </div>
                </>
            )}

        </nav>
    );
}

export default Navi;
