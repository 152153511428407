// src/components/TailwindButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TailwindButton = ({ text, onClick, type = 'button', style = 'primary', width = 'w-auto', height = 'h-auto', to }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (to) {
            navigate(to);
        } else if (onClick) {
            onClick();
        }
    };

    const buttonStyles = {
        primary: 'bg-mainColor hover:bg-red-500 text-white text-xl font-bold py-2 px-4 rounded transition duration-300 ease-in-out rounded-lg',
        secondary: 'bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out',
        success: 'bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out',
        danger: 'bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out',
    };

    return (
        <button
            type={type}
            onClick={handleClick}
            className={`${buttonStyles[style]} ${width} ${height}`}
        >
            {text}
        </button>
    );
};

export default TailwindButton;
