import React, { useState } from 'react';

const AssessmentModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        contactNumber: '',
        email: '',
        preferredDate: '',
        preferredTime: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data:', formData);
        // Handle form submission logic here (e.g., send data to server)
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 font-twcen overflow-y-auto">
            <div className="bg-mainColor rounded-3xl shadow pt-4 px-4 pb-4 xl:w-1/2 w-3/4 max-h-full overflow-y-auto">
                <div className="flex xl:justify-end justify-between items-center pb-2">
                    <h3 className="xl:hidden block text-normal font-semibold text-white ">Schedule an Assessment</h3>
                    <button onClick={onClose} className="text-secondaryColor hover:bg-red-400 rounded-lg p-1">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                {/* Desktop View */}
                <div className="hidden xl:block border-4 border-secondaryColor rounded-3xl p-2">
                    <div className="w-full flex flex-col items-center justify-center mx-auto px-2">
                        <div className='w-2/3 text-center justify-center items-center my-auto'>
                            <h2 className="mt-4 text-5xl text-white">Schedule an Assessment</h2>
                            <p className="mb-4 text-2xl text-white">
                                Please fill out the form to schedule an assessment.
                            </p>
                        </div>
                        <div className="w-3/4 px-8 pb-4 rounded-2xl">
                            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                                <div className="mb-4">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="firstName">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="lastName">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="contactNumber">
                                        Contact Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="contactNumber"
                                        name="contactNumber"
                                        value={formData.contactNumber}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-2 col-span-2">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="preferredDate">
                                        Preferred Date
                                    </label>
                                    <input
                                        type="date"
                                        id="preferredDate"
                                        name="preferredDate"
                                        value={formData.preferredDate}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-2 col-span-2">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="preferredTime">
                                        Preferred Time
                                    </label>
                                    <input
                                        type="time"
                                        id="preferredTime"
                                        name="preferredTime"
                                        value={formData.preferredTime}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="col-span-2 flex items-center justify-center mt-2">
                                    <button
                                        type="submit"
                                        className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Mobile View */}
                <div className="xl:hidden block bg-mainColor border-2 border-secondaryColor rounded-3xl max-h-[75vh] overflow-y-auto">
                    <div className="w-full flex flex-col items-center">
                        <div className='p-4 text-center my-auto'>
                            <p className="text-xl text-white leading-none">
                                Please fill out the form to schedule an assessment.
                            </p>
                        </div>
                        <div className="bg-mainColor px-5 pb-2 rounded-2xl w-full text-center mb-2">
                            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-1">
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="firstName">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="lastName">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="contactNumber">
                                        Contact Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="contactNumber"
                                        name="contactNumber"
                                        value={formData.contactNumber}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="preferredDate">
                                        Preferred Date
                                    </label>
                                    <input
                                        type="date"
                                        id="preferredDate"
                                        name="preferredDate"
                                        value={formData.preferredDate}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-white text-xl font-bold mb-2" htmlFor="preferredTime">
                                        Preferred Time
                                    </label>
                                    <input
                                        type="time"
                                        id="preferredTime"
                                        name="preferredTime"
                                        value={formData.preferredTime}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="flex items-center justify-center mt-2">
                                    <button
                                        type="submit"
                                        className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssessmentModal;
