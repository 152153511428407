import React from 'react';
import HeaderHero from '../components/headerhero_section';
import HeaderImage from '../assets/healthcareteam.jpg';
import Breadcrumbs from '../components/breadcrumbs';
import TeamImage from '../assets/team.jpg';
import TeamApproachImage from '../assets/teamapproach.png';

const HealthCareTeam = () => {
    return (
        <div className='font-twcen border-b-1 border-black shadow-md'>
            <HeaderHero
                imageUrl={HeaderImage}
                title="Health Care Team"
            />
            <div className='flex flex-col xl:w-3/4 w-full items-center justify-center mx-auto px-5 xl:px-0'>
                <div className='w-full items-center text-center xl:text-left mt-5'>
                    <Breadcrumbs />
                </div>
                <div className='w-full border-b-2 border-dashed border-gray-500 mt-4'></div>

                <div className='w-full my-5 xl:text-2xl text-xl'>
                    {/* Desktop Text */}
                    <div className='hidden xl:block'>
                        <div
                            className="flex flex-row justify-around items-center rounded-3xl my-3 py-5"
                        >
                            <div className='max-w-3/4 ml-10 pr-10'>
                                <p className='mb-4'>
                                    Our dedicated team of disciplines and hospice professionals share the values and vision of Love & Faith Hospice Services Inc; that is to provide excellent service not only to our patients but to the families we serve as well.
                                </p>
                                <p className='mb-4'>
                                    They have undergone series of training and competency testing prior to providing care to our patients.</p>
                            </div>
                            <div className='mr-10'>
                                <img src={TeamImage} alt="Team image" className="w-full rounded-3xl" />
                            </div>
                        </div>
                    </div>

                    {/* Mobile Text */}
                    <div className='xl:hidden block text-justify text-xl'>
                        <div
                            className="flex flex-col justify-around items-center my-3"
                        >
                            <div className='mx-5'>
                                <img src={TeamImage} alt="Team image" className="w-full rounded-3xl" />
                            </div>
                            <div className='mt-5'>
                                <p className='mb-4'>
                                    Our dedicated team of disciplines and hospice professionals share the values and vision of Love & Faith Hospice Services Inc; that is to provide excellent service not only to our patients but to the families we serve as well.
                                </p>
                                <p className='mb-4'>
                                    They have undergone series of training and competency testing prior to providing care to our patients.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Desktop Team Approach */}
                <div id="approach-desktop" className="hidden xl:block text-black">
                    <div className="flex flex-col justify-around items-left rounded-3xl">
                        <div className='mx-5'>
                            <h2 className="text-5xl font-normal text-mainColor text-left">
                                TEAM APPROACH
                            </h2>
                            <h3 className="text-4xl font-normal text-black text-left ml-1 -mt-2">
                                to Patient Care
                            </h3>
                        </div>
                    </div>
                    <div className='flex justify-center mx-auto mb-10'>
                        <img src={TeamApproachImage} alt="Team Approach Diagram image" className="w-3/4 rounded-3xl" />
                    </div>
                </div>


                {/* Mobile Team Approach*/}
                <div id="approach-mobile" className="block xl:hidden text-black">
                    <div className="flex flex-col justify-around items-center rounded-3xl">
                        <div className='mx-5'>
                            <h2 className="text-3xl font-semibold text-mainColor text-center">
                                TEAM APPROACH
                            </h2>
                            <h3 className="text-2xl font-normal text-black text-center -mt-2">
                                to Patient Care
                            </h3>
                        </div>
                    </div>
                    <div className='flex justify-center -mx-2 mt-2 mb-10'>
                        <img src={TeamApproachImage} alt="Team Approach Diagram image" className="w-full rounded-3xl" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthCareTeam;
