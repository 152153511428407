import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Menu = ({ closeMenu }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [openSubmenu, setOpenSubmenu] = useState('');

    const isActive = (path) => location.pathname === path;

    const handleMouseEnter = (submenu) => {
        setOpenSubmenu(submenu);
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            setOpenSubmenu('');
        }, 10000);
    };

    const scrollToSection = (sectionId) => {
        const desktopSection = document.getElementById(`${sectionId}-desktop`);
        const mobileSection = document.getElementById(`${sectionId}-mobile`);
        const section = window.innerWidth >= 1280 ? desktopSection : mobileSection;

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth'
            });
        }
        closeMenu(); // Close menu after scrolling
    };

    const handleMenuClick = (hasSubmenu, sectionId) => {
        if (sectionId) {
            scrollToSection(sectionId);
        } else if (!hasSubmenu) {
            closeMenu();
        }
    };

    const handleSubmenuItemClick = (path, sectionId) => {
        navigate(path);
        setTimeout(() => {
            scrollToSection(sectionId);
        }, 500);
    };

    return (
        <div className="flex xl:flex-row flex-col items-center justify-center py-3 space-y-2 space-x-0 xl:space-x-8 font-medium">
            <RouterLink
                to="/"
                className={`block md:inline-block px-3 py-2 rounded-md text-2xl xl:text-3xl focus:outline-none ${isActive('/')
                    ? 'text-secondaryColor'
                    : 'text-white hover:underline'
                    }`}
                onClick={() => handleMenuClick(false)} // Close menu when link is clicked
            >
                Home
            </RouterLink>
            <span className="hidden xl:block text-white text-3xl">•</span>
            <div
                className="relative"
                onMouseEnter={() => handleMouseEnter('about')}
                onMouseLeave={handleMouseLeave}
            >
                <RouterLink
                    to="/about"
                    className={`flex items-center justify-between w-full px-2 py-2 rounded-md text-2xl xl:text-3xl text-center focus:outline-none ${isActive('/about')
                        ? 'text-secondaryColor'
                        : 'text-white hover:underline'
                        } xl:justify-center xl:w-auto`}
                    onClick={() => handleMenuClick(true)} // Don't close menu when link is clicked
                >
                    <span>About Us</span>
                    <span className="ml-2">
                        {openSubmenu === 'about' ? <IoIosArrowUp className="text-xl" /> : <IoIosArrowDown className="text-xl" />}
                    </span>
                </RouterLink>
                {openSubmenu === 'about' && (
                    <div
                        className="w-full xl:absolute xl:left-0 xl:mt-2 xl:w-60 xl:bg-white bg-mainColor xl:shadow-lg xl:rounded-md xl:py-2 xl:z-10 text-center"
                        onMouseEnter={() => setOpenSubmenu('about')}
                        onMouseLeave={() => setOpenSubmenu('')}
                    >
                        <div onClick={() => handleSubmenuItemClick('/about', 'promise')} className="block mx-2 px-5 py-2 text-white xl:text-black hover:bg-gray-200 hover:text-black rounded-md text-xl cursor-pointer">Our Promise to you</div>
                        <div onClick={() => handleSubmenuItemClick('/about', 'mission')} className="block mx-2 px-5 py-2 text-white xl:text-black hover:bg-gray-200 hover:text-black rounded-md text-xl cursor-pointer">Mission Statement</div>
                        <div onClick={() => handleSubmenuItemClick('/about', 'hospice')} className="block mx-2 px-5 py-2 text-white xl:text-black hover:bg-gray-200 hover:text-black rounded-md text-xl cursor-pointer">What is Hospice</div>
                    </div>
                )}
            </div>
            <span className="hidden xl:block text-white text-3xl">•</span>
            <div
                className="relative"
                onMouseEnter={() => handleMouseEnter('services')}
                onMouseLeave={handleMouseLeave}
            >
                <RouterLink
                    to="/services"
                    className={`flex items-center justify-between w-full px-3 py-2 rounded-md text-2xl xl:text-3xl text-center focus:outline-none ${isActive('/services')
                        ? 'text-secondaryColor'
                        : 'text-white hover:underline'
                        } xl:justify-center xl:w-auto`}
                    onClick={() => handleMenuClick(true)} // Don't close menu when link is clicked
                >
                    <span>Hospice Services</span>
                    <span className="ml-2">
                        {openSubmenu === 'services' ? <IoIosArrowUp className="text-xl" /> : <IoIosArrowDown className="text-xl" />}
                    </span>
                </RouterLink>
                {openSubmenu === 'services' && (
                    <div
                        className="w-full xl:absolute xl:left-0 xl:mt-2 xl:w-60 xl:bg-white bg-mainColor xl:shadow-lg xl:rounded-md xl:py-2 xl:z-10 text-center"
                        onMouseEnter={() => setOpenSubmenu('services')}
                        onMouseLeave={() => setOpenSubmenu('')}
                    >
                        <div onClick={() => handleSubmenuItemClick('/services', 'benefits')} className="block mx-2 px-5 py-2 text-white xl:text-black hover:bg-gray-200 hover:text-black rounded-md text-xl cursor-pointer">Love and Faith Hospice Benefits</div>
                        <div onClick={() => handleSubmenuItemClick('/services', 'coverage')} className="block mx-2 px-5 py-2 text-white xl:text-black hover:bg-gray-200 hover:text-black rounded-md text-xl cursor-pointer">Our Coverage Area</div>
                        <div onClick={() => handleSubmenuItemClick('/services', 'hospice')} className="block mx-2 px-5 py-2 text-white xl:text-black hover:bg-gray-200 hover:text-black rounded-md text-xl cursor-pointer">Hospice Levels Of Care</div>
                        <div onClick={() => handleSubmenuItemClick('/services', 'bereavement')} className="block mx-2 px-5 py-2 text-white xl:text-black hover:bg-gray-200 hover:text-black rounded-md text-xl cursor-pointer">Bereavement Services</div>
                        <div onClick={() => handleSubmenuItemClick('/services', 'volunteer')} className="block mx-2 px-5 py-2 text-white xl:text-black hover:bg-gray-200 hover:text-black rounded-md text-xl cursor-pointer">Volunteer Program</div>
                    </div>
                )}
            </div>
            <span className="hidden xl:block text-white text-3xl">•</span>
            <RouterLink
                to="/team"
                className={`block md:inline-block px-3 py-2 rounded-md text-2xl xl:text-3xl focus:outline-none ${isActive('/team')
                    ? 'text-secondaryColor'
                    : 'text-white hover:underline'
                    }`}
                onClick={() => handleMenuClick(false)} // Close menu when link is clicked
            >
                Health Care Team
            </RouterLink>
            <span className="hidden xl:block text-white text-3xl">•</span>
            <RouterLink
                to="/contact"
                className={`block md:inline-block px-3 py-2 rounded-md text-2xl xl:text-3xl focus:outline-none ${isActive('/contact')
                    ? 'text-secondaryColor'
                    : 'text-white hover:underline'
                    }`}
                onClick={() => handleMenuClick(false)} // Close menu when link is clicked
            >
                Contact Us
            </RouterLink>
        </div>
    );
};

export default Menu;
