import React from 'react';
import PropTypes from 'prop-types';

const HeaderHero = ({ imageUrl, title }) => {
    return (
        <div className="xl:h-1/2 h-60 flex flex-col text-center relative justify-end xl:mt-[-112px]">
            <div className="absolute inset-0 bg-cover bg-center opacity-100" style={{ backgroundImage: `url(${imageUrl})` }}></div>
            <div className="relative z-10 flex flex-col h-full justify-end w-full">
                <div className="w-full bg-black bg-opacity-50 py-5 absolute bottom-0">
                    <h1 className="text-4xl md:text-5xl text-white font-semibold">{title}</h1>
                </div>
            </div>
        </div>
    );
};

HeaderHero.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    title: PropTypes.string,
};

HeaderHero.defaultProps = {
    title: 'Header Title',
};

export default HeaderHero;
