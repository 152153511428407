import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderHero from '../components/headerhero_section';
import HeaderImage from '../assets/contactus.jpg';
import { IoLocationSharp, IoCall, IoMail } from "react-icons/io5";
import { FaFax } from "react-icons/fa";
import Breadcrumbs from '../components/breadcrumbs';
import LogoImage from '../assets/LogoTransparentWeb.png';
import QuestionForm from '../components/forms/questionform'

const ContactUs = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#help') {
            const element = document.getElementById('help');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <div className='font-twcen border-b-1 border-black shadow-md'>
            <HeaderHero
                imageUrl={HeaderImage}
                title="Contact Us"
            />
            <div className='flex flex-col xl:w-3/4 w-full items-center justify-center mx-auto px-5 xl:px-0'>
                <div className='w-full items-center text-center xl:text-left mt-5'>
                    <Breadcrumbs />
                </div>
                <div className='w-full border-b-2 border-dashed border-gray-500 mt-4'></div>

                {/* Desktop Our Promise To You*/}
                <div id="always-desktop" className="hidden xl:block text-mainColor w-3/4 px-10 py-5 justify-center items-center mx-auto" style={{ borderRadius: "40px" }}>
                    <div
                        className="flex flex-row justify-center items-center rounded-3xl my-3 py-5"
                    >
                        <div className='text-center'>
                            <h2 className="text-6xl pb-5 font-semibold">ALWAYS THERE FOR YOU</h2>
                            <p className="text-3xl text-center text-black">We are available 24/7, including holidays, for new referrals, concerns, or sudden changes in condition. Feel free to call us anytime.</p>
                        </div>
                    </div>
                </div>

                {/* Mobile Our Promise To You*/}
                <div id="always-mobile" className="xl:hidden block text-mainColor" style={{ borderRadius: "40px" }}>
                    <div
                        className="flex flex-col justify-center items-center rounded-3xl my-2 py-5"
                    >
                        <div className='mx-6 my-5'>
                            <h2 className="text-2xl pb-3 font-semibold text-center">ALWAYS THERE FOR YOU</h2>
                            <p className="text-xl text-center text-black">We are available 24/7, including holidays, for new referrals, concerns, or sudden changes in condition. Feel free to call us anytime.</p>
                        </div>
                    </div>
                </div>

                <div className='w-full xl:text-2xl text-xl rounded-3xl bg-white'>
                    {/* Desktop Text */}
                    <div className='hidden xl:block'>
                        <div
                            className="flex flex-col justify-around items-center rounded-3xl my-3 py-5"
                        >
                            <div className='flex item-center justify-center mx-auto mb-10'>
                                <img src={LogoImage} alt="Logo image" className="w-1/2 rounded-3xl" />
                            </div>

                            <div className='flex flex-row m-2 items-start justify-center p-2 space-x-4 lg:space-x-8'>
                                <div className='flex items-center justify-start text-left p-4 rounded-lg'>
                                    <div className=''>
                                        <div className='flex flex-col items-center justify-center'>
                                            <IoLocationSharp className="text-mainColor lg:text-8xl text-3xl mb-2" />
                                            <h1 className='lg:text-5xl text-xl text-mainColor'>Address</h1>
                                        </div>
                                        <h1 className='lg:text-3xl text-xl'>7365 Carnelian St. Suite 115</h1>
                                        <h1 className='lg:text-3xl text-xl'>Rancho Cucamonga, CA 91730</h1>
                                    </div>
                                </div>
                                <div className='flex items-center justify-start text-left p-4 rounded-lg'>
                                    <div className=''>
                                        <div className='flex flex-col items-center justify-center'>
                                            <IoCall className="text-mainColor lg:text-8xl text-3xl mb-2" />
                                            <h1 className='lg:text-5xl text-xl text-mainColor'>Phone</h1>
                                        </div>
                                        <a href="tel:+19092848901" className='lg:text-3xl text-xl no-underline hover:underline'>
                                            (909) 284-8901
                                        </a>
                                    </div>
                                </div>
                                <div className='flex items-center justify-start text-left p-4 rounded-lg'>
                                    <div className=''>
                                        <div className='flex flex-col items-center justify-center'>
                                            <FaFax className="text-mainColor lg:text-8xl text-3xl mb-2" />
                                            <h1 className='lg:text-5xl text-xl text-mainColor'>Fax</h1>
                                        </div>
                                        <a href="fax:+19092848902" className='lg:text-3xl text-xl no-underline hover:underline'>
                                            (909) 284-8902
                                        </a>
                                    </div>
                                </div>
                                <div className='flex items-center justify-start text-left p-4 rounded-lg'>
                                    <div className=''>
                                        <div className='flex flex-col items-center justify-center'>
                                            <IoMail className="text-mainColor lg:text-8xl text-3xl mb-2" />
                                            <h1 className='lg:text-5xl text-xl text-mainColor'>Email</h1>
                                        </div>
                                        <a href="mailto:info@loveandfaithhospice.com" className="lg:text-3xl text-xl no-underline hover:underline">
                                            info@loveandfaithhospice.com
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Mobile Text */}
                    <div className='xl:hidden block text-justify text-xl'>
                        <div className="flex flex-col justify-around items-center my-3">
                            <div className='mx-5'>
                                <img src={LogoImage} alt="Team image" className="w-full rounded-3xl" />
                            </div>

                            <div className='flex flex-col m-2 items-center justify-center space-y-4 p-4 rounded-lg'>
                                <div className='flex items-center justify-center text-center p-4 rounded-lg'>
                                    <div className=''>
                                        <div className='flex flex-col items-center justify-center'>
                                            <IoLocationSharp className="text-mainColor text-5xl mb-2" />
                                            <h1 className='text-3xl text-mainColor'>Address</h1>
                                        </div>
                                        <h1 className='text-xl'>7365 Carnelian St. Suite 115</h1>
                                        <h1 className='text-xl'>Rancho Cucamonga, CA 91730</h1>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center text-center p-4 rounded-lg'>
                                    <div className=''>
                                        <div className='flex flex-col items-center justify-center'>
                                            <IoCall className="text-mainColor text-5xl mb-2" />
                                            <h1 className='text-3xl text-mainColor'>Phone</h1>
                                        </div>
                                        <a href="tel:+19092848901" className='text-xl no-underline hover:underline'>
                                            (909) 284-8901
                                        </a>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center text-center p-4 rounded-lg'>
                                    <div className=''>
                                        <div className='flex flex-col items-center justify-center'>
                                            <FaFax className="text-mainColor text-5xl mb-2" />
                                            <h1 className='text-3xl text-mainColor'>Fax</h1>
                                        </div>
                                        <a href="fax:+19092848902" className='text-xl no-underline hover:underline'>
                                            (909) 284-8902
                                        </a>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center text-center p-4 rounded-lg'>
                                    <div className=''>
                                        <div className='flex flex-col items-center justify-center'>
                                            <IoMail className="text-mainColor text-5xl mb-2" />
                                            <h1 className='text-3xl text-mainColor'>Email</h1>
                                        </div>
                                        <a href="mailto:info@loveandfaithhospice.com" className="text-xl no-underline hover:underline">
                                            info@loveandfaithhospice.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='help' className="flex w-full h-full items-center justify-center">
                    <QuestionForm />
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
